import { createAction } from 'redux-actions';

import apiAdmin from '../../configs/api-admin';
import server from '../../utils/server';

const {
  GET_NOTICE_LIST_REQUEST,
  GET_NOTICE_LIST_SUCCESS,
} = require('../constants').default;

export const getNoticeListRequest = createAction(GET_NOTICE_LIST_REQUEST);
export const getNoticeListSuccess = createAction(GET_NOTICE_LIST_SUCCESS);

export function getNoticeList() {
  return (dispatch) => {
    dispatch(getNoticeListRequest());
    server.get(apiAdmin.branch.notice.list(), (data) => {
      dispatch(getNoticeListSuccess(data.res.list));
    });
  };
}

export function acceptAll() {
  return (dispatch) => {
    server.get(apiAdmin.branch.notice.acceptAll(), () => {
      dispatch(getNoticeList());
    });
  };
}

export function accept(id) {
  return (dispatch) => {
    return new Promise((resolve) => {
      server.post(apiAdmin.branch.notice.accept(), {
        push_id: id,
      }, () => {
        dispatch(getNoticeList());
        resolve(true);
      });
    });
  };
}
