import React from 'react';
import { Redirect } from 'react-router-dom';

import LazyRoute from 'lazy-route';
import AuthUtil from '../../utils/AuthUtil';

const orderOrDealer = AuthUtil.isHeadquarterRisk() ? (
  <Redirect to="/youche/workbench" />
) : (
  <Redirect to="/dashboard/summary/today" />
);

const routers = [
  {
    path: '/',
    breadcrumbName: '首页/',
    exact: true,
    render: () => (AuthUtil.isLogin() ? orderOrDealer : <Redirect to="/login" />),
  },

  // 0. 数据看板
  {
    breadcrumbName: '数据看板/概览/今日数据',
    path: '/dashboard/summary/today',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/summary/Today')} />
    ),
  },
  {
    breadcrumbName: '数据看板/概览/累计数据',
    path: '/dashboard/summary/history',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/summary/History')} />
    ),
  },
  {
    breadcrumbName: '数据看板/汽修门店/门店概况',
    path: '/dashboard/repair-shop/index',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./dashboard/repair-shop/Index')}
      />
    ),
  },
  {
    breadcrumbName: '数据看板/汽修门店/业务统计',
    path: '/dashboard/repair-shop/business',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./dashboard/repair-shop/Business.js')}
      />
    ),
  },
  {
    breadcrumbName: '数据看板/汽修门店/客户统计',
    path: '/dashboard/repair-shop/customer-manage',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./dashboard/repair-shop/Customer')}
      />
    ),
  },
  {
    breadcrumbName: '数据看板/汽修门店/使用统计',
    path: '/dashboard/repair-shop/usage',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/repair-shop/Use')} />
    ),
  },
  {
    breadcrumbName: '数据看板/城市团队/团队概况',
    path: '/dashboard/team/index',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/team/Index')} />
    ),
  },
  {
    breadcrumbName: '数据看板/城市团队/业务统计',
    path: '/dashboard/team/business',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/team/Business')} />
    ),
  },
  {
    breadcrumbName: '数据看板/城市团队/拜访统计',
    path: '/dashboard/team/visit',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/team/Visit')} />
    ),
  },
  {
    breadcrumbName: '数据看板/城市团队/使用统计',
    path: '/dashboard/team/usage',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/team/Use')} />
    ),
  },
  {
    breadcrumbName: '数据看板/汽配团队/团队概况',
    path: '/dashboard/part-team/index',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/part-team/Index')} />
    ),
  },
  {
    breadcrumbName: '数据看板/汽配团队/业务统计',
    path: '/dashboard/part-team/business',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./dashboard/part-team/Business')}
      />
    ),
  },
  {
    breadcrumbName: '数据看板/汽配团队/拜访统计',
    path: '/dashboard/part-team/visit',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/part-team/Visit')} />
    ),
  },
  {
    breadcrumbName: '数据看板/汽配团队/使用统计',
    path: '/dashboard/part-team/usage',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/part-team/Use')} />
    ),
  },
  {
    breadcrumbName: '数据看板/功能模块/小程序',
    path: '/dashboard/business/weapp',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/business/Weapp')} />
    ),
  },
  {
    breadcrumbName: '数据看板/功能模块/公众号',
    path: '/dashboard/business/wechat',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/business/Wechat')} />
    ),
  },
  {
    breadcrumbName: '数据看板/功能模块/问答',
    path: '/dashboard/business/qa',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/business/Qa')} />
    ),
  },
  {
    breadcrumbName: '数据看板/功能模块/短信',
    path: '/dashboard/business/sms',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/business/Sms')} />
    ),
  },

  // 1. 业务管理
  {
    breadcrumbName: '业务管理/配件销售/品牌',
    path: '/business/brand',
    render: props => (
      <LazyRoute {...props} component={import('./business/brand/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/配件管理',
    path: '/business/part',
    render: props => (
      <LazyRoute {...props} component={import('./business/part/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/商品管理',
    path: '/business/goods-item',
    render: props => (
      <LazyRoute {...props} component={import('./business/goods-item/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/商品类别',
    path: '/business/goods',
    render: props => (
      <LazyRoute {...props} component={import('./business/goods/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/品牌意向',
    path: '/business/brand-intention',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/brand-intention/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/市场洞察',
    path: '/business/market-insight',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/market-insight/Index')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '业务管理/活动管理',
    path: '/business/activity',
    render: props => (
      <LazyRoute {...props} component={import('./business/activity/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '业务管理/品牌活动',
    path: '/business/activity-brand',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/activity-brand/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '业务管理/门店活动/报名活动',
    path: '/business/activity-event',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/activity-event/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '业务管理/活动管理',
    path: '/business/activity-logs',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/activity-logs/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/活动管理',
    path: '/business/activity-logs/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/activity-logs/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/订单管理',
    path: '/business/order',
    render: props => (
      <LazyRoute {...props} component={import('./business/order/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/订单削减',
    path: '/business/order-cut',
    render: props => (
      <LazyRoute {...props} component={import('./business/order-cut/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/退货/拒收',
    path: '/business/order-return',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/order-return/Index')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/资金管理/支付汇总',
    path: '/business/finance/transfer',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/finance/transfer/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/资金管理/应收账款',
    path: '/business/finance/on-account',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/finance/on-account/Index')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '业务管理/转账/线上收款',
    path: '/business/finance/payments',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/finance/payments/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/资金管理/滞纳金管理',
    path: '/business/finance/overdue-fine',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/finance/overdue-fine/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/资金管理/市场费用',
    path: '/business/finance/market-wallet',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/finance/market-wallet/Index')}
      />
    ),
  },
  /* {
    breadcrumbName: '业务管理/转账/转账记录',
    path: '/business/finance/transfer-log',
    render: props => (
      <LazyRoute {...props} component={import('./business/finance/transfer-log/List')} />
    ),
  }, */ {
    breadcrumbName: '门店活动/推送记录',
    path: '/business/news-push-log',
    render: props => (
      <LazyRoute {...props} component={import('./business/push/List')} />
    ),
  },
  {
    breadcrumbName: '拜访管理/系统使用记录',
    path: '/business/use-log',
    render: props => (
      <LazyRoute {...props} component={import('./business/use-log/List')} />
    ),
  },
  {
    breadcrumbName: '拜访管理/直播记录',
    path: '/business/wxwork-living-log',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/wxwork-living-log/List')}
      />
    ),
  },
  {
    breadcrumbName: '拜访管理/拜访记录',
    path: '/business/visit',
    render: props => (
      <LazyRoute {...props} component={import('./business/visit/List')} />
    ),
  },
  {
    breadcrumbName: '拜访管理/拜访计划',
    path: '/business/visit-plan',
    render: props => (
      <LazyRoute {...props} component={import('./business/visit-plan/List')} />
    ),
  },
  {
    breadcrumbName: '仓库管理/仓库管理',
    path: '/warehouse/index',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/warehouse/warehouse-manage/List')}
      />
    ),
  },
  {
    breadcrumbName: '仓库管理/仓库盘点',
    path: '/warehouse/stocktaking',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/warehouse/stocktaking/List')}
      />
    ),
  },
  {
    breadcrumbName: '仓库管理/损益管理',
    path: '/warehouse/income-statement',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/warehouse/income-statement/List')}
      />
    ),
  },
  {
    breadcrumbName: '仓库管理/出库管理',
    path: '/warehouse/output',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/warehouse/output/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/数据收集',
    path: '/business/journal',
    render: props => (
      <LazyRoute {...props} component={import('./business/journal/List')} />
    ),
  },
  {
    breadcrumbName: '业务管理/保养业务/套餐卡管理',
    path: '/business/maintain/coupon-card',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/maintain/coupon-card/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/保养业务/顾客线索',
    path: '/business/maintain/customer-clue',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/maintain/customer-clue/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/保养业务/购买记录',
    path: '/business/maintain/buy-logs-log',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/maintain/buy-logs/List')}
      />
    ),
  },
  {
    breadcrumbName: '业务管理/保养业务/核销记录',
    path: '/business/maintain/consume',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./business/maintain/consume/List')}
      />
    ),
  },

  // 2. 水稻优车
  {
    exact: true,
    breadcrumbName: '工作台',
    path: '/youche/workbench',
    render: props => (
      <LazyRoute {...props} component={import('./youche/order/Workbench')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '通知',
    path: '/youche/notices',
    render: props => (
      <LazyRoute {...props} component={import('./youche/order/Notices')} />
    ),
  },
  {
    breadcrumbName: '水稻优车/经销商管理',
    path: '/youche/dealer',
    render: props => (
      <LazyRoute {...props} component={import('./youche/dealer/Index')} />
    ),
  },
  {
    breadcrumbName: '水稻优车/产品/车型产品',
    path: '/youche/product/vehicle',
    render: props => (
      <LazyRoute {...props} component={import('./youche/product/Vehicle')} />
    ),
  },
  {
    breadcrumbName: '水稻优车/产品/金融产品',
    path: '/youche/product/finance',
    render: props => (
      <LazyRoute {...props} component={import('./youche/product/Finance')} />
    ),
  },
  {
    breadcrumbName: '水稻优车/产品/产品管理／创建',
    path: '/youche/product/add/:id',
    render: props => (
      <LazyRoute {...props} component={import('./youche/product/Add')} />
    ),
  },
  {
    breadcrumbName: '水稻优车/产品/产品管理／编辑',
    path: '/youche/product/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./youche/product/Edit')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/方案/固定首尾付',
    path: '/youche/plan/vehicle',
    render: props => (
      <LazyRoute {...props} component={import('./youche/plan/vehicle/Index')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/方案/贷款分期',
    path: '/youche/plan/finance',
    render: props => (
      <LazyRoute {...props} component={import('./youche/plan/finance/Index')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/订单/意向订单',
    path: '/youche/order/intention',
    render: props => (
      <LazyRoute {...props} component={import('./youche/order/Intention')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/订单/订单管理',
    path: '/youche/order',
    render: props => (
      <LazyRoute {...props} component={import('./youche/order/Order')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/订单/订单管理',
    path: '/youche/order/filter/:bizType/:status',
    render: props => (
      <LazyRoute {...props} component={import('./youche/order/Order')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/订单/订单管理/详情',
    path: '/youche/order/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./youche/order/Detail')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/订单/收益记录',
    path: '/youche/order/profit',
    render: props => (
      <LazyRoute {...props} component={import('./youche/profit/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/订单/订单统计',
    path: '/youche/order/statistics',
    render: props => (
      <LazyRoute {...props} component={import('./youche/statistic/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/设置/资源方管理',
    path: '/youche/resource',
    render: props => (
      <LazyRoute {...props} component={import('./youche/resource/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/设置/材料配置',
    path: '/youche/material',
    render: props => (
      <LazyRoute {...props} component={import('./youche/material/List')} />
    ),
  },

  // 3. 门店管家
  {
    path: '/daotian/repair-shop/clue',
    breadcrumbName: '线索管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair-shop/clue/List')}
      />
    ),
  },
  {
    path: '/daotian/repair-shop/company',
    breadcrumbName: '维修厂管理',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/repair-shop/Index')} />
    ),
  },
  {
    breadcrumbName: '数据看板/汽修门店/详情',
    path: '/daotian/repair-shop/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair-shop/Detail')}
      />
    ),
  },
  {
    path: '/daotian/repair-shop/work-order',
    breadcrumbName: '工单管理',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/work-order/List')} />
    ),
  },
  /* {
    breadcrumbName: '服务购买',
    path: '/daotian/dealer/service-purchase',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/dealer/service-purchase/Index')} />
    ),
  }, */ {
    path: '/daotian/repair-shop/company-active',
    breadcrumbName: '门店激活',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/company-active/List')}
      />
    ),
  },
  {
    path: '/daotian/repair-shop/inspection-report',
    breadcrumbName: '车检报告',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/inspection-report/List')}
      />
    ),
  },
  {
    path: '/daotian/repair-shop/admin-follow-up',
    breadcrumbName: '总部跟进',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair-shop/admin-follow-up/List')}
      />
    ),
  },
  {
    path: '/daotian/repair-shop/integral',
    breadcrumbName: '积分管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair-shop/integral/Index')}
      />
    ),
  },
  {
    path: '/daotian/receive-money-qrcode/income',
    breadcrumbName: '门店资金/收款管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/receive-money-qrcode/income/List')}
      />
    ),
  },
  {
    path: '/daotian/receive-money-qrcode/withdraw',
    breadcrumbName: '门店资金/提现管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/receive-money-qrcode/withdraw/List')}
      />
    ),
  },
  {
    path: '/daotian/personnel-wallet/income',
    breadcrumbName: '员工资金/收款管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/personnel-wallet/income/List')}
      />
    ),
  },
  {
    path: '/daotian/personnel-wallet/withdraw',
    breadcrumbName: '员工资金/提现管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/personnel-wallet/withdraw/List')}
      />
    ),
  },
  {
    path: '/daotian/customer-wallet/income',
    breadcrumbName: '车主资金/收款管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/customer-wallet/income/List')}
      />
    ),
  },
  {
    path: '/daotian/customer-wallet/withdraw',
    breadcrumbName: '车主资金/提现管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/customer-wallet/withdraw/List')}
      />
    ),
  },
  {
    path: '/daotian/customer-wallet/wechat-pay',
    breadcrumbName: '车主资金/微信直接付款',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/customer-wallet/wechat-pay/List')}
      />
    ),
  },
  {
    breadcrumbName: '公众号管理/车主服务号',
    path: '/daotian/wechat/customer',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/wechat/customer/Index')}
      />
    ),
  },
  {
    breadcrumbName: '公众号管理/门店服务号',
    path: '/daotian/wechat/company',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/wechat/company/List')}
      />
    ),
  },
  {
    breadcrumbName: '公众号管理/水稻订阅号',
    path: '/daotian/wechat/shuidao-auto',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/wechat/shuidao-auto/List')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音活动',
    path: '/daotian/douyin-activity',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-activity/List')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音车主/客户粉丝画像',
    path: '/daotian/douyin-customer/fans-portrait/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-customer/FansPortrait')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音小程序车主',
    path: '/daotian/douyin-customer/microapp',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-microapp/Index')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音咨询管理',
    path: '/daotian/douyin-ask-manages',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-ask-manages/Index')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音车主/详情',
    path: '/daotian/douyin-customer/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-customer/DetailLink')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音车主',
    path: '/daotian/douyin-customer',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-customer/List')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音视频/详情',
    path: '/daotian/douyin-video/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-video/DetailLink')}
      />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音视频',
    path: '/daotian/douyin-video/:id',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/douyin-video/List')} />
    ),
  },
  {
    breadcrumbName: '抖音管理/抖音视频',
    path: '/daotian/douyin-video',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/douyin-video/List')} />
    ),
  },
  {
    breadcrumbName: '抖音管理/视频发送计划',
    path: '/daotian/douyin-video-plan',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/douyin-video-plan/List')}
      />
    ),
  },

  // 快手
  {
    breadcrumbName: '快手管理/快手车主',
    path: '/daotian/kuaishou-customer',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/kuaishou/kuaishou-customer/List')}
      />
    ),
  },
  {
    breadcrumbName: '快手管理/快手视频',
    path: '/daotian/kuaishou-video/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/kuaishou/kuaishou-video/List')}
      />
    ),
  },
  {
    breadcrumbName: '快手管理/快手视频',
    path: '/daotian/kuaishou-video',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/kuaishou/kuaishou-video/List')}
      />
    ),
  },

  {
    breadcrumbName: '活动管理/套餐管理/套餐模板',
    path: '/daotian/repair/activity/template',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/activity-template/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/套餐管理/套餐管理',
    path: '/daotian/repair/activity',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/activity/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/套餐管理/活动奖品',
    path: '/daotian/repair/activity-prize',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/activity/ActivityPrize')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/套餐管理/套餐购买',
    path: '/daotian/repair/activity-pay',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/activity-pay/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/套餐管理/套餐核销',
    path: '/daotian/repair/package-item-consume',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/package-item-consume/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/套餐管理/销售线索',
    path: '/daotian/repair/activity-clue',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/activity-clue/List')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/会员管理/会员模板',
    path: '/daotian/repair/member/activity/template',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/member/activity-template/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/会员管理/会员管理',
    path: '/daotian/repair/member/activity',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/member/activity/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/会员管理/会员购买',
    path: '/daotian/repair/member/activity-pay',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/member/activity-pay/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/会员管理/会员核销',
    path: '/daotian/repair/member/package-item-consume',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/member/package-item-consume/Index')}
      />
    ),
  },
  {
    breadcrumbName: '活动管理/会员管理/销售线索',
    path: '/daotian/repair/member/activity-clue',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/member/activity-clue/List')}
      />
    ),
  },
  {
    breadcrumbName: '汽修小程序/商户管理',
    path: '/daotian/repair/commercial-tenant',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/commercial-tenant/Index')}
      />
    ),
  },
  {
    breadcrumbName: '汽修小程序/服务购买',
    path: '/daotian/repair/service-purchase',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/service-purchase/Index')}
      />
    ),
  },
  {
    breadcrumbName: '汽修小程序/配件采购单',
    path: '/daotian/repair/part-order',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/part-order/Index')}
      />
    ),
  },
  {
    breadcrumbName: '汽修小程序/旧件管理',
    path: '/daotian/repair/old-part',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/old-part/Index')}
      />
    ),
  },
  {
    breadcrumbName: '汽修小程序/挪车码管理',
    path: '/daotian/repair/move-qrcode',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair/move-qrcode/Index')}
      />
    ),
  },
  {
    breadcrumbName: '汽贸小程序/商户管理',
    path: '/daotian/dealer/commercial-tenant',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/dealer/commercial-tenant/Index')}
      />
    ),
  },
  /* {
    breadcrumbName: '汽贸小程序/活动模板',
    path: '/daotian/dealer/activity/template',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/dealer/activity-template/Index')} />
    ),
  }, {
    breadcrumbName: '汽贸小程序/活动管理',
    path: '/daotian/dealer/activity',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/dealer/activity/Index')} />
    ),
  }, {
    breadcrumbName: '汽贸小程序/活动购买',
    path: '/daotian/dealer/activity-pay',
    render: props => (
      <LazyRoute {...props} component={import('./daotian/dealer/activity-pay/Index')} />
    ),
  }, */ {
    breadcrumbName: '车主小程序/车主管理',
    path: '/daotian/customer/customer-manage',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/customer/customer-manage/Index')}
      />
    ),
  },
  {
    breadcrumbName: '车主小程序/挪车码管理',
    path: '/daotian/customer/auto-manage',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/customer/auto-manage/Index')}
      />
    ),
  },
  {
    breadcrumbName: '车主小程序/线索管理',
    path: '/daotian/customer/clue-manage',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/customer/clue-manage/Index')}
      />
    ),
  },

  // 新能源
  {
    breadcrumbName: '新能源管理/车检设备/检测枪',
    path: '/new-energy/detection-gun-management/index',
    render: props => (
      <LazyRoute {...props} component={import('./new-energy/detection-gun-management/device/List.js')} />
    ),
  },
  {
    breadcrumbName: '新能源管理/车检设备/检测结果/详情',
    path: '/new-energy/detection-gun-management/check-result/detail',
    render: props => (
      <LazyRoute {...props} component={import('./new-energy/detection-gun-management/check-result/Detail.js')} />
    ),
  },
  {
    breadcrumbName: '新能源管理/车检设备/检测结果',
    path: '/new-energy/detection-gun-management/check-result/index',
    render: props => (
      <LazyRoute {...props} component={import('./new-energy/detection-gun-management/check-result/List.js')} />
    ),
  },
  {
    breadcrumbName: '新能源管理/车检设备',
    path: '/new-energy/diagnose',
    render: props => (
      <LazyRoute {...props} component={import('./new-energy/diagnose/List')} />
    ),
  },
  {
    breadcrumbName: '新能源管理/课程管理/商品',
    path: '/new-energy/course-goods',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/course-goods/Index')}
      />
    ),
  },
  {
    breadcrumbName: '新能源管理/课程管理/优惠券',
    path: '/new-energy/course-coupon',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/course-coupon/Index')}
      />
    ),
  },
  {
    breadcrumbName: '新能源管理/水稻商场',
    path: '/new-energy/shuidao-goods',
    render: props => (
      <LazyRoute {...props} component={import('./new-energy/electric-product/List')} />
    ),
  },
  {
    breadcrumbName: '新能源管理/新能源课程/线下课程',
    path: '/new-energy/electric-course/offline',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/electric-course/offline/List')}
      />
    ),
  },
  {
    breadcrumbName: '新能源管理/新能源课程/线上课程',
    path: '/new-energy/electric-course/online',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/electric-course/online/List')}
      />
    ),
  },
  {
    breadcrumbName: '新能源管理/门店线索/线索管理',
    path: '/new-energy/clue',
    render: props => (
      <LazyRoute {...props} component={import('./new-energy/clue/List')} />
    ),
  },
  {
    breadcrumbName: '新能源管理/门店线索/线索跟进',
    path: '/new-energy/clue-follow-up',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/admin-follow-up/List')}
      />
    ),
  },
  {
    breadcrumbName: '新能源管理/电桩管理/充电站',
    path: '/new-energy/charge/station',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/charge/station/List')}
      />
    ),
  },
  {
    breadcrumbName: '新能源管理/电桩管理/订单管理',
    path: '/new-energy/charge/order',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/charge/order/List')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/技术支持/维修资料',
    path: '/new-energy/maintain-material',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/maintain-material/List')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/技术支持/维修案例',
    path: '/new-energy/maintain-case',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/maintain-case/List')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/技术支持/资料A整理',
    path: '/new-energy/zjxc-material',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/zjxc-material/List')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/技术支持/智慧修车/详情',
    path: '/new-energy/maintain-qas/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/maintain-qas/Detail')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/技术支持/智慧修车',
    path: '/new-energy/maintain-qas',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/maintain-qas/List')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/门店管理/入住门店/详情',
    path: '/new-energy/company/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./daotian/repair-shop/Detail')}
      />
    ),
  }, {
    breadcrumbName: '新能源管理/门店管理/入住门店',
    path: '/new-energy/company',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./new-energy/company/List')}
      />
    ),
  },

  // 4.水稻汽配
  {
    breadcrumbName: '水稻汽配/汽配商品/订单管理',
    path: '/part-dealer/order',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/order/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配商品/商品管理',
    path: '/part-dealer/goods-item',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/goods-item/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配商品/商品类别',
    path: '/part-dealer/goods',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/goods/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配商品/品牌意向',
    path: '/part-dealer/brand-intention',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/brand-intention/List')}
      />
    ),
  },
  {
    path: '/part-dealer/shuidao-goods/goods-item',
    breadcrumbName: '水稻汽配/水稻商品/商品管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/shuidao-goods/goods-item/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻汽配/水稻商品/商品类别',
    path: '/part-dealer/shuidao-goods/goods',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/shuidao-goods/goods/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻汽配/水稻商品/订单管理',
    path: '/part-dealer/shuidao-goods/order',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/shuidao-goods/order/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻汽配/水稻商品/订单管理/详情',
    path: '/part-dealer/shuidao-goods/order/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/shuidao-goods/order/DetailLink')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/数据统计/推送记录',
    path: '/part-dealer/news-push-log',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/push/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/数据统计/使用记录',
    path: '/part-dealer/use-log',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/use-log/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/数据统计/拜访记录',
    path: '/part-dealer/visit',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/visit/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/数据统计/拜访计划',
    path: '/part-dealer/visit-plan',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/visit-plan/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/数据统计/数据收集',
    path: '/part-dealer/journal',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/journal/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/门店活动/优惠活动',
    path: '/part-dealer/activity',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/activity/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/门店活动/品牌活动',
    path: '/part-dealer/activity-brand',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/activity-brand/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/门店活动/一物一码',
    path: '/part-dealer/anti-fake-code',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/anti-fake-code/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/门店活动/延保订单',
    path: '/part-dealer/extension-insurance',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/extension-insurance/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/门店活动/活动报名',
    path: '/part-dealer/event',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/activity/event/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/门店活动/会议管理',
    path: '/part-dealer/conference',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/conference/List')}
      />
    ),
  }, /* {    breadcrumbName: '水稻汽配/门店活动/会议资金管理',
    path: '/part-dealer/conference-wallet',
    render: props => (
      <LazyRoute{...props} component={import('./part-dealer/conference-wallet/List')} />
    ),
  }, */

  // 活动资金
  {
    path: '/wallet/activity/charge',
    breadcrumbName: '水稻汽配/活动资金/充值管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/wallet/activity/charge/List')}
      />
    ),
  },
  {
    path: '/wallet/activity/consume',
    breadcrumbName: '水稻汽配/活动资金/消费管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/wallet/activity/consume/List')}
      />
    ),
  },
  {
    path: '/system-wallet/charge',
    breadcrumbName: '水稻汽配/系统资金/充值管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/sys-wallet/charge/List')}
      />
    ),
  },
  {
    path: '/system-wallet/consume',
    breadcrumbName: '水稻汽配/系统资金/消费管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/sys-wallet/consume/List')}
      />
    ),
  },
  {
    path: '/part-dealer/team/detail/:id',
    breadcrumbName: '水稻汽配/汽配商/团队管理详情',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/team/Detail')} />
    ),
  },
  {
    path: '/part-dealer/team',
    breadcrumbName: '水稻汽配/汽配商/团队管理',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/team/Index')} />
    ),
  },
  {
    path: '/part-dealer/user-track',
    breadcrumbName: '水稻汽配/汽配商/员工轨迹',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/user/TrackMap')} />
    ),
  },
  {
    path: '/part-dealer/user-yingyan-track',
    breadcrumbName: '水稻汽配/汽配商/员工鹰眼轨迹',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/user/YingYan-Track')}
      />
    ),
  },
  {
    path: '/part-dealer/user',
    breadcrumbName: '水稻汽配/汽配商/员工管理',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/user/List')} />
    ),
  },
  {
    path: '/part-dealer/finance/payments',
    breadcrumbName: '水稻汽配/交易管理/收支管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/finance/payments/List')}
      />
    ),
  },
  {
    path: '/part-dealer/finance/transfer',
    breadcrumbName: '水稻汽配/交易管理/转账管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/finance/transfer/List')}
      />
    ),
  },
  {
    path: '/part-dealer/finance/transfer-log',
    breadcrumbName: '水稻汽配/交易管理/转账记录',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/finance/transfer-log/List')}
      />
    ),
  },
  {
    path: '/wallet/income',
    breadcrumbName: '水稻汽配/汽配资金/收款管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/wallet/income/List')}
      />
    ),
  },
  {
    path: '/wallet/withdraw',
    breadcrumbName: '水稻汽配/汽配资金/提现管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/wallet/withdraw/List')}
      />
    ),
  }, /* , {    path: '/user-wallet/income',    breadcrumbName: '水稻汽配/汽配资金/员工资金管理',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/user-wallet/income/List')} />
    ),
  }, {
    path: '/user-wallet/withdraw',
    breadcrumbName: '水稻汽配/汽配资金/员工资金管理',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/user-wallet/withdraw/List')} />
    ),
  } */
  {
    breadcrumbName: '水稻汽配/汽配小程序/商家管理',
    path: '/part-dealer/business',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/business/Index')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配小程序/保证金管理',
    path: '/part-dealer/deposit',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/deposit/Index')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配小程序/报价管理',
    path: '/part-dealer/quote',
    render: props => (
      <LazyRoute {...props} component={import('./part-dealer/quote/Index')} />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配小程序/旧件库存',
    path: '/part-dealer/old-part',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/old-part/Index')}
      />
    ),
  },
  {
    breadcrumbName: '水稻汽配/汽配小程序/配件采购单',
    path: '/part-dealer/part-order',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./part-dealer/part-order/Index')}
      />
    ),
  },
  // 5.水稻管车
  {
    exact: true,
    breadcrumbName: '水稻管车/公司管理',
    path: '/guanche/company',
    render: props => (
      <LazyRoute {...props} component={import('./guanche/company/Index')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管车/短信管理',
    path: '/guanche/sms-template',
    render: props => (
      <LazyRoute {...props} component={import('./guanche/sms/Index')} />
    ),
  },
  {
    path: '/guanche/wallet/charge',
    breadcrumbName: '资金管理/充值管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./guanche/wallet/charge/List')}
      />
    ),
  },
  {
    path: '/guanche/wallet/consume',
    breadcrumbName: '资金管理/消费管理',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./guanche/wallet/consume/List')}
      />
    ),
  },

  // 6. 产品运营
  {
    breadcrumbName: '水稻管家/教程管理',
    path: '/operation/daotian/tutorial',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/tutorial/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/水稻学院',
    path: '/operation/daotian/repair-course',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/course/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/小米有品',
    path: '/operation/daotian/mi-cps',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/mi-cps/Index')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/使用管理',
    path: '/operation/daotian/logs',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/log/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/推送管理',
    path: '/operation/daotian/push',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/push/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/广告管理',
    path: '/operation/daotian/advert',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/advert/List')}
      />
    ),
  },
  /* , {
    breadcrumbName: '水稻管家/资讯管理',
    path: '/operation/daotian/news',
    render: props => (
      <LazyRoute {...props} component={import('./operation/daotian/news/List')} />
    ),
  } */ {
    breadcrumbName: '水稻管家/素材图片',
    path: '/operation/daotian/material/image',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/image/List')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/素材视频',
    path: '/operation/daotian/material/video',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/video/Index')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/素材资讯',
    path: '/operation/daotian/material/news',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/news/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管家/添加素材',
    path: '/operation/daotian/material/editor',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/news/Editor')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管家/编辑素材',
    path: '/operation/daotian/material/editor/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/news/Editor')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管家/添加脚本素材',
    path: '/operation/daotian/material/script/editor',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/script/Editor')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管家/编辑脚本素材',
    path: '/operation/daotian/material/script/editor/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/script/Editor')}
      />
    ),
  },
  {
    breadcrumbName: '水稻管家/脚本素材',
    path: '/operation/daotian/material/script',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/material/script/Index')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '产品运营/水稻管家/收款管理',
    path: '/operation/daotian/wallet',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/daotian/wallet/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/群发素材/新增',
    path: '/operation/branch/material/new',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/material/Edit')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/群发素材/编辑',
    path: '/operation/branch/material/edit/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/material/Edit')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/群发素材/详情',
    path: '/operation/branch/material/detail/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/material/Detail')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/素材使用',
    path: '/operation/branch/material/use-log',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/material/UseLog')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/素材使用',
    path: '/operation/branch/material/use-log/:id',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/material/UseLog')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/群发素材',
    path: '/operation/branch/material',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/material/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/分享历史',
    path: '/operation/branch/material/share-log',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/share-log/List')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '城市团队/水稻学院',
    path: '/operation/branch/branch-course',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/branch/course/Index')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管家/添加素材',
    path: '/operation/client/news/editor',
    render: props => (
      <LazyRoute {...props} component={import('./operation/news/Editor')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻管家/编辑素材',
    path: '/operation/client/news/editor/:id',
    render: props => (
      <LazyRoute {...props} component={import('./operation/news/Editor')} />
    ),
  },
  {
    breadcrumbName: '水稻汽车/媒体中心',
    path: '/operation/client/news',
    render: props => (
      <LazyRoute {...props} component={import('./operation/news/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽车/广告管理',
    path: '/operation/client/advert',
    render: props => (
      <LazyRoute {...props} component={import('./operation/advert/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽车/活动管理',
    path: '/operation/client/activity',
    render: props => (
      <LazyRoute {...props} component={import('./operation/activity/List')} />
    ),
  },
  {
    breadcrumbName: '水稻汽车/评价管理',
    path: '/operation/client/comment',
    render: props => (
      <LazyRoute {...props} component={import('./operation/comment/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻技师版/问答管理/列表',
    path: '/operation/question',
    render: props => (
      <LazyRoute {...props} component={import('./operation/question/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻技师版/问答管理/详情',
    path: '/operation/question/detail',
    render: props => (
      <LazyRoute {...props} component={import('./operation/question/Detail')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻技师版/问答管理/详情',
    path: '/operation/question/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./operation/question/Detail')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻技师版/技师管理/列表',
    path: '/operation/artificer',
    render: props => (
      <LazyRoute {...props} component={import('./operation/artificer/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻技师版/技师管理/详情',
    path: '/operation/artificer/detail',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/artificer/Detail')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻技师版/技师管理/详情',
    path: '/operation/artificer/detail/:customerId',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./operation/artificer/Detail')}
      />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/广告位管理',
    path: '/operation/youche/advert',
    render: props => (
      <LazyRoute {...props} component={import('./youche/advert/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '水稻优车/问答管理',
    path: '/operation/youche/qa',
    render: props => (
      <LazyRoute {...props} component={import('./youche/qa/List')} />
    ),
  },

  // 7.数据管理
  {
    exact: true,
    path: '/data/vehicle',
    breadcrumbName: '车辆管理/列表',
    render: props => (
      <LazyRoute {...props} component={import('./data-manage/vehicle/List')} />
    ),
  },
  {
    exact: true,
    path: '/data/vehicle/detail/:id',
    breadcrumbName: '车辆管理/详情',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/vehicle/detail/Index')}
      />
    ),
  },
  {
    exact: true,
    path: '/data/vehicle-owner/list',
    breadcrumbName: '车主管理/列表',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/vehicle-owner/List')}
      />
    ),
  },
  {
    path: '/data/remind/maintain',
    breadcrumbName: '提醒管理/保养提醒',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/remind/maintain/Index')}
      />
    ),
  },
  {
    path: '/data/remind/insurance',
    breadcrumbName: '提醒管理/续保提醒',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/remind/insurance/Index')}
      />
    ),
  },
  {
    path: '/data/remind/yearly-inspection',
    breadcrumbName: '提醒管理/年检提醒',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/remind/yearly-inspection/Index')}
      />
    ),
  },
  {
    exact: true,
    path: '/data/sms/history',
    breadcrumbName: '短信管理/历史明细',
    render: props => (
      <LazyRoute {...props} component={import('./data-manage/sms/History')} />
    ),
  },
  {
    exact: true,
    path: '/data/sms/history-voice',
    breadcrumbName: '短信管理/历史明细/语音短信',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/sms/voice/List')}
      />
    ),
  },
  {
    exact: true,
    path: '/data/sms/charge',
    breadcrumbName: '短信管理/充值记录',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/sms/charge/Index')}
      />
    ),
  },
  {
    exact: true,
    path: '/data/sms/template',
    breadcrumbName: '短信管理/模板管理',
    render: props => (
      <LazyRoute {...props} component={import('./data-manage/sms/Template')} />
    ),
  },
  {
    exact: true,
    path: '/data/sms/new-template',
    breadcrumbName: '新建模板',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/sms/NewTemplate')}
      />
    ),
  },
  {
    exact: true,
    path: '/data/sms/new-template/:type',
    breadcrumbName: '新建模板',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/sms/NewTemplate')}
      />
    ),
  },
  {
    exact: true,
    path: '/data/sms/edit-template/:id',
    breadcrumbName: '编辑模板',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/sms/NewTemplate')}
      />
    ),
  },
  {
    breadcrumbName: '数据配置/维修项目',
    path: '/data/data-manage/maintain-item',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./data-manage/maintain-item/List')}
      />
    ),
  },

  // 8.设置
  {
    breadcrumbName: '设置/功能设置',
    path: '/settings/system',
    render: props => (
      <LazyRoute {...props} component={import('./settings/system/Index')} />
    ),
  },
  {
    breadcrumbName: '设置/权限管理/系统权限',
    path: '/settings/permission/edition',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./settings/permission/Edition')}
      />
    ),
  },
  {
    breadcrumbName: '设置/权限管理/角色权限',
    path: '/settings/permission/role',
    render: props => (
      <LazyRoute {...props} component={import('./settings/permission/Role')} />
    ),
  },
  {
    breadcrumbName: '汽配权限管理/系统权限',
    path: '/settings/dealer/system',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./settings/dealer/system/Index')}
      />
    ),
  },
  {
    breadcrumbName: '汽配权限管理/角色权限',
    path: '/settings/dealer/role',
    render: props => (
      <LazyRoute {...props} component={import('./settings/dealer/role/Role')} />
    ),
  },
  {
    breadcrumbName: '城市权限管理/系统权限',
    path: '/settings/branch/system',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./settings/branch/system/Index')}
      />
    ),
  },
  {
    breadcrumbName: '城市权限管理/角色权限',
    path: '/settings/branch/role',
    render: props => (
      <LazyRoute {...props} component={import('./settings/branch/role/Role')} />
    ),
  },
  {
    breadcrumbName: '设置/账号管理/列表',
    path: '/settings/account',
    render: props => (
      <LazyRoute {...props} component={import('./settings/account/List')} />
    ),
  },
  {
    breadcrumbName: '设置/城市团队管理',
    path: '/branch/team/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./settings/team/Detail')} />
    ),
  },
  {
    breadcrumbName: '设置/城市团队管理',
    path: '/branch/team',
    render: props => (
      <LazyRoute {...props} component={import('./settings/team/Index')} />
    ),
  },
  {
    breadcrumbName: '设置/城市团队/员工轨迹',
    path: '/branch/user-track',
    render: props => (
      <LazyRoute {...props} component={import('./settings/user/TrackMap')} />
    ),
  },
  {
    path: '/branch/user-yingyan-track',
    breadcrumbName: '设置/城市团队/员工鹰眼轨迹',
    render: props => (
      <LazyRoute
        {...props}
        component={import('./settings/user/YingYan-Track')}
      />
    ),
  },
  {
    breadcrumbName: '设置/城市团队/员工管理',
    path: '/branch/user',
    render: props => (
      <LazyRoute {...props} component={import('./settings/user/Index')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '设置/车型维护',
    path: '/settings/auto-models',
    render: props => (
      <LazyRoute {...props} component={import('./settings/models/List')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '设置/车型维护/新建',
    path: '/settings/auto-models/new',
    render: props => (
      <LazyRoute {...props} component={import('./settings/models/New')} />
    ),
  },
  {
    exact: true,
    breadcrumbName: '设置/车型维护/新建',
    path: '/settings/auto-models/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./settings/models/New')} />
    ),
  },

  // test
  {
    exact: true,
    breadcrumbName: '测试',
    path: '/test',
    render: props => <LazyRoute {...props} component={import('../Test')} />,
  },

  // error pages
  {
    breadcrumbName: '403/',
    path: '/permission-403',
    render: props => <LazyRoute {...props} component={import('../403')} />,
  },
  {
    breadcrumbName: '404/',
    path: '*',
    render: props => <LazyRoute {...props} component={import('../404')} />,
  },
];

export default routers;
