import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ConfigProvider } from 'antd'; // antd 默认为英文， 这里改为中文
import zhCN from 'antd/lib/locale-provider/zh_CN';

import AppAdmin from './App';
import Login from './Login';

const Root = ({ store }) => (
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/" component={AppAdmin} />
        </Switch>
      </Router>
    </Provider>
  </ConfigProvider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
