import {
  DashboardOutlined, ShopOutlined, TeamOutlined, AppstoreOutlined,
  MoneyCollectOutlined, MedicineBoxOutlined, NotificationOutlined, DesktopOutlined,
  PayCircleOutlined, BulbOutlined, DatabaseOutlined, CalendarOutlined, HomeOutlined,
  FileTextOutlined, SettingOutlined, MessageOutlined, CarOutlined, UserOutlined,
  LockOutlined, ContactsOutlined,
} from '@ant-design/icons';

const menuAdminNewEnergy = require('./menu-admin-newenergy').default;
const menuAdminChengShi = require('./menu-admin-chengshi').default;

const menuAdmin = [
  {
    key: 'dashboard',
    name: '数据看板',
    menus: [
      {
        key: 'dashboard_summary',
        icon: DashboardOutlined,
        name: '概览',
        subMenu: [
          {
            name: '今日数据',
            path: '/dashboard/summary/today',
          }, {
            name: '累计数据',
            path: '/dashboard/summary/history',
          },
        ],
      }, {
        key: 'dashboard_store',
        icon: ShopOutlined,
        name: '汽修门店',
        subMenu: [
          {
            name: '门店概况',
            path: '/dashboard/repair-shop/index',
          }, {
            name: '业务统计',
            path: '/dashboard/repair-shop/business',
          }, {
            name: '使用统计',
            path: '/dashboard/repair-shop/usage',
          }, {
            name: '客户统计',
            path: '/dashboard/repair-shop/customer-manage',
          },
        ],
      }, {
        key: 'dashboard_team',
        icon: TeamOutlined,
        name: '城市团队',
        subMenu: [
          {
            name: '团队概况',
            path: '/dashboard/team/index',
          }, {
            name: '业务统计',
            path: '/dashboard/team/business',
          }, {
            name: '拜访统计',
            path: '/dashboard/team/visit',
          }, {
            name: '使用统计',
            path: '/dashboard/team/usage',
          },
        ],
      }, {
        key: 'dashboard_part_team',
        icon: TeamOutlined,
        name: '汽配团队',
        subMenu: [
          {
            name: '团队概况',
            path: '/dashboard/part-team/index',
          }, {
            name: '业务统计',
            path: '/dashboard/part-team/business',
          }, {
            name: '拜访统计',
            path: '/dashboard/part-team/visit',
          }, {
            name: '使用统计',
            path: '/dashboard/part-team/usage',
          },
        ],
      }, {
        key: 'dashboard_function',
        icon: AppstoreOutlined,
        name: '功能模块',
        subMenu: [
          {
            name: '小程序',
            path: '/dashboard/business/weapp',
          }, {
            name: '公众号',
            path: '/dashboard/business/wechat',
          }, {
            name: '问答',
            path: '/dashboard/business/qa',
          }, {
            name: '短信',
            path: '/dashboard/business/sms',
          },
        ],
      },
    ],
  }, {
    ...menuAdminChengShi[0],
  }, {
    key: 'daotian',
    name: '门店管家',
    menus: [
      {
        key: 'daotian_store',
        icon: ShopOutlined,
        name: '维修厂',
        subMenu: [
          {
            name: '维修厂管理',
            path: '/daotian/repair-shop/company',
          }, {
            name: '线索管理',
            path: '/daotian/repair-shop/clue',
          }, {
            name: '工单管理',
            path: '/daotian/repair-shop/work-order',
          }, {
            name: '服务购买',
            path: '/daotian/repair/service-purchase',
          }, {
            name: '门店激活',
            path: '/daotian/repair-shop/company-active',
          }, {
            name: '车检报告',
            path: '/daotian/repair-shop/inspection-report',
          }, {
            name: '总部跟进',
            path: '/daotian/repair-shop/admin-follow-up',
          }, {
            name: '积分管理',
            path: '/daotian/repair-shop/integral',
          },
        ],
      }, {
        key: 'activity_manage',
        icon: CalendarOutlined,
        name: '活动管理',
        subMenu: [
          {
            name: '套餐管理',
            key: 'set-meal',
            type: 'subMenu',
            children: [
              {
                name: '套餐管理',
                path: '/daotian/repair/activity',
              }, {
                name: '活动奖品',
                path: '/daotian/repair/activity-prize',
              }, {
                name: '套餐模板',
                path: '/daotian/repair/activity/template',
              }, {
                name: '套餐购买',
                path: '/daotian/repair/activity-pay',
              }, {
                name: '套餐核销',
                path: '/daotian/repair/package-item-consume',
              }, {
                name: '销售线索',
                path: '/daotian/repair/activity-clue',
              },
            ],
          }, {
            name: '会员管理',
            key: 'member',
            type: 'subMenu',
            children: [
              {
                name: '会员管理',
                path: '/daotian/repair/member/activity',
              }, {
                name: '会员模板',
                path: '/daotian/repair/member/activity/template',
              }, {
                name: '会员购买',
                path: '/daotian/repair/member/activity-pay',
              }, {
                name: '会员核销',
                path: '/daotian/repair/member/package-item-consume',
              }, {
                name: '销售线索',
                path: '/daotian/repair/member/activity-clue',
              },
            ],
          },
        ],
      }, {
        key: 'daotian_receive_money_qrcode',
        icon: PayCircleOutlined,
        name: '门店资金',
        subMenu: [
          {
            name: '收款管理',
            path: '/daotian/receive-money-qrcode/income',
          }, {
            name: '提现管理',
            path: '/daotian/receive-money-qrcode/withdraw',
          },
        ],
      }, {
        key: 'daotian_personnel_wallet',
        icon: PayCircleOutlined,
        name: '员工资金',
        subMenu: [
          {
            name: '收款管理',
            path: '/daotian/personnel-wallet/income',
          }, {
            name: '提现管理',
            path: '/daotian/personnel-wallet/withdraw',
          },
        ],
      }, {
        key: 'daotian_customer_wallet',
        icon: PayCircleOutlined,
        name: '车主资金',
        subMenu: [
          {
            name: '收款管理',
            path: '/daotian/customer-wallet/income',
          }, {
            name: '提现管理',
            path: '/daotian/customer-wallet/withdraw',
          }, {
            name: '微信直接付款',
            path: '/daotian/customer-wallet/wechat-pay',
          },
        ],
      }, {
        key: 'daotian_wechat',
        icon: MedicineBoxOutlined,
        name: '公众号管理',
        subMenu: [
          {
            name: '车主服务号',
            path: '/daotian/wechat/customer',
          }, {
            name: '门店服务号',
            path: '/daotian/wechat/company',
          }, {
            name: '水稻订阅号',
            path: '/daotian/wechat/shuidao-auto',
          },
        ],
      }, {
        key: 'daotian_douyin',
        icon: MedicineBoxOutlined,
        name: '抖音管理',
        subMenu: [
          {
            name: '抖音活动',
            path: '/daotian/douyin-activity',
          }, {
            name: '小程序车主',
            path: '/daotian/douyin-customer/microapp',
          }, {
            name: '抖音车主',
            path: '/daotian/douyin-customer',
          }, {
            name: '抖音视频',
            path: '/daotian/douyin-video',
          }, {
            name: '咨询管理',
            path: '/daotian/douyin-ask-manages',
          }, {
            name: '视频发送计划',
            path: '/daotian/douyin-video-plan',
          },
        ],
      }, {
        key: 'daotian_kuaishou',
        icon: MedicineBoxOutlined,
        name: '快手管理',
        subMenu: [
          {
            name: '快手车主',
            path: '/daotian/kuaishou-customer',
          }, {
            name: '快手视频',
            path: '/daotian/kuaishou-video',
          },
        ],
      }, {
        key: 'daotian_repair',
        icon: TeamOutlined,
        name: '汽修小程序',
        subMenu: [
          {
            name: '商户管理',
            path: '/daotian/repair/commercial-tenant',
          }, {
            name: '配件采购单',
            path: '/daotian/repair/part-order',
          }, {
            name: '旧件管理',
            path: '/daotian/repair/old-part',
          }, {
            name: '挪车码管理',
            path: '/daotian/repair/move-qrcode',
          },
        ],
      }, {
        key: 'daotian_dealer',
        icon: AppstoreOutlined,
        name: '汽贸小程序',
        subMenu: [
          {
            name: '商户管理',
            path: '/daotian/dealer/commercial-tenant',
          }, /* {
            name: '活动管理',
            path: '/daotian/dealer/activity',
          }, {
            name: '活动模板',
            path: '/daotian/dealer/activity/template',
          }, {
            name: '活动购买',
            path: '/daotian/dealer/activity-pay',
          }, {
            name: '服务购买',
            path: '/daotian/dealer/service-purchase',
          }, */
        ],
      }, {
        key: 'daotian_owner',
        icon: BulbOutlined,
        name: '车主小程序',
        subMenu: [
          {
            name: '车主管理',
            path: '/daotian/customer/customer-manage',
          }, {
            name: '挪车码管理',
            path: '/daotian/customer/auto-manage',
          }, {
            name: '线索管理',
            path: '/daotian/customer/clue-manage',
          },
        ],
      },
    ],
  }, {
    key: 'dealerD',
    name: '水稻汽配',
    menus: [
      {
        key: 'dealerToD_order',
        icon: CalendarOutlined,
        name: '汽配商品',
        subMenu: [
          {
            name: '订单管理',
            path: '/part-dealer/order',
          }, {
            name: '商品管理',
            path: '/part-dealer/goods-item',
          }, {
            name: '商品类别',
            path: '/part-dealer/goods',
          }, {
            name: '品牌意向',
            path: '/part-dealer/brand-intention',
          },
        ],
      }, {
        key: 'shuidao_goods',
        icon: AppstoreOutlined,
        name: '水稻商品',
        subMenu: [
          {
            name: '订单管理',
            path: '/part-dealer/shuidao-goods/order',
          }, {
            name: '商品管理',
            path: '/part-dealer/shuidao-goods/goods-item',
          }, {
            name: '商品类别',
            path: '/part-dealer/shuidao-goods/goods',
          },
        ],
      }, {
        key: 'dealer_manage_use_log',
        icon: DesktopOutlined,
        name: '数据统计',
        subMenu: [
          {
            name: '推送记录',
            path: '/part-dealer/news-push-log',
          }, {
            name: '使用记录',
            path: '/part-dealer/use-log',
          }, {
            name: '拜访记录',
            path: '/part-dealer/visit',
          }, {
            name: '数据收集',
            path: '/part-dealer/journal',
          }, {
            name: '拜访计划',
            path: '/part-dealer/visit-plan',
          },
        ],
      }, {
        key: 'dealer_manage_activity',
        icon: NotificationOutlined,
        name: '门店活动',
        subMenu: [
          {
            name: '优惠活动',
            path: '/part-dealer/activity',
          }, /* , {
            name: '品牌活动',
            path: '/part-dealer/activity-brand',
          } */ {
            name: '一物一码',
            path: '/part-dealer/anti-fake-code',
          }, {
            name: '延保订单',
            path: '/part-dealer/extension-insurance',
          }, {
            name: '活动报名',
            path: '/part-dealer/event',
          }, {
            name: '会议管理',
            path: '/part-dealer/conference',
          }, /* {
            name: '会议资金管理',
            path: '/part-dealer/conference-wallet',
          }, */
        ],
      }, /* {
        key: 'dealer_user_wallet',
        icon: PayCircleOutlined,
        name: '员工资金',
        subMenu: [
          {
            name: '收款管理',
            path: '/user-wallet/income',
          }, {
            name: '提现管理',
            path: '/user-wallet/withdraw',
          },
        ],
      }, */ {
        key: 'dealer_wallet_activity',
        icon: PayCircleOutlined,
        name: '活动资金',
        subMenu: [
          {
            name: '充值管理',
            path: '/wallet/activity/charge',
          }, {
            name: '消费管理',
            path: '/wallet/activity/consume',
          },
        ],
      }, {
        key: 'dealer_system_wallet',
        icon: PayCircleOutlined,
        name: '系统资金',
        subMenu: [
          {
            name: '充值管理',
            path: '/system-wallet/charge',
          }, {
            name: '消费管理',
            path: '/system-wallet/consume',
          },
        ],
      }, {
        key: 'dealer_system_setting_team',
        icon: TeamOutlined,
        name: '汽配商',
        subMenu: [
          {
            name: '团队管理',
            path: '/part-dealer/team',
          }, {
            name: '员工管理',
            path: '/part-dealer/user',
          },
        ],
      }, {
        key: 'dealer_manage_money',
        icon: MoneyCollectOutlined,
        name: '交易管理',
        subMenu: [
          {
            name: '转账管理',
            path: '/part-dealer/finance/transfer',
          }, {
            name: '收支管理',
            path: '/part-dealer/finance/payments',
          }, {
            name: '转账记录',
            path: '/part-dealer/finance/transfer-log',
          },
        ],
      }, {
        key: 'dealer_wallet',
        icon: PayCircleOutlined,
        name: '汽配资金',
        subMenu: [
          {
            name: '收款管理',
            path: '/wallet/income',
          }, {
            name: '提现管理',
            path: '/wallet/withdraw',
          },
        ],
      }, {
        key: 'part-dealer',
        icon: HomeOutlined,
        name: '汽配小程序',
        subMenu: [
          {
            name: '商家管理',
            path: '/part-dealer/business',
          }, {
            name: '保证金管理',
            path: '/part-dealer/deposit',
          }, {
            name: '报价管理',
            path: '/part-dealer/quote',
          }, {
            name: '旧件库存',
            path: '/part-dealer/old-part',
          }, {
            name: '配件采购单',
            path: '/part-dealer/part-order',
          },
        ],
      },
    ],
  }, {
    ...menuAdminNewEnergy[0],
  }, {
    key: 'daotianToD',
    name: '水稻优车',
    menus: [
      {
        key: 'daotianToD_agent',
        icon: HomeOutlined,
        name: '经销商',
        subMenu: [
          {
            name: '经销商管理',
            path: '/youche/dealer',
          },
        ],
      }, {
        key: 'daotianToD_product',
        icon: AppstoreOutlined,
        name: '产品',
        subMenu: [
          {
            name: '固定首尾付',
            path: '/youche/product/vehicle',
          }, {
            name: '贷款分期',
            path: '/youche/product/finance',
          },
        ],
      }, {
        key: 'daotianToD_plan',
        icon: FileTextOutlined,
        name: '方案',
        subMenu: [
          {
            name: '固定首尾付',
            path: '/youche/plan/vehicle',
          }, {
            name: '贷款分期',
            path: '/youche/plan/finance',
          },
        ],
      }, {
        key: 'daotianToD_order',
        icon: PayCircleOutlined,
        name: '订单',
        subMenu: [
          {
            name: '意向订单',
            path: '/youche/order/intention',
          }, {
            name: '订单管理',
            path: '/youche/order',
          }, {
            name: '订单收益',
            path: '/youche/order/profit',
          }, {
            name: '订单统计',
            path: '/youche/order/statistics',
          },
        ],
      }, {
        key: 'daotianToD_config',
        icon: SettingOutlined,
        name: '设置',
        subMenu: [
          {
            name: '资源方管理',
            path: '/youche/resource',
          }, {
            name: '材料配置',
            path: '/youche/material',
          },
        ],
      },
    ],
  }, {
    key: 'daotianToE',
    name: '水稻管车',
    menus: [
      {
        key: 'daotianToE_company',
        icon: HomeOutlined,
        name: '公司',
        subMenu: [
          {
            name: '公司管理',
            path: '/guanche/company',
          },
        ],
      }, {
        key: 'daotianToE_sms_template',
        icon: MessageOutlined,
        name: '短信',
        subMenu: [
          {
            name: '模板管理',
            path: '/guanche/sms-template',
          },
        ],
      }, {
        key: 'daotianToE_wallet',
        icon: PayCircleOutlined,
        name: '资金管理',
        subMenu: [
          {
            name: '充值管理',
            path: '/guanche/wallet/charge',
          }, {
            name: '消费管理',
            path: '/guanche/wallet/consume',
          },
        ],
      },
    ],
  }, {
    key: 'admin_product_operation',
    name: '产品运营',
    menus: [
      {
        key: 'product_daotian',
        icon: ShopOutlined,
        name: '水稻管家',
        subMenu: [
          {
            name: '教程管理',
            path: '/operation/daotian/tutorial',
          }, {
            name: '水稻学院',
            path: '/operation/daotian/repair-course',
          }, {
            name: '小米有品',
            path: '/operation/daotian/mi-cps',
          }, /* {
            name: '资讯管理',
            path: '/operation/daotian/news',
          } */ {
            name: '使用管理',
            path: '/operation/daotian/logs',
          }, {
            name: '推送管理',
            path: '/operation/daotian/push',
          }, {
            name: '素材图片',
            path: '/operation/daotian/material/image',
          }, {
            name: '素材视频',
            path: '/operation/daotian/material/video',
          }, {
            name: '素材资讯',
            path: '/operation/daotian/material/news',
          }, {
            name: '脚本素材',
            path: '/operation/daotian/material/script',
          }, {
            name: '收款管理',
            path: '/operation/daotian/wallet',
          },
        ],
      }, {
        key: 'product_branch',
        icon: TeamOutlined,
        name: '城市团队',
        subMenu: [
          {
            name: '群发素材',
            path: '/operation/branch/material',
          },
          {
            name: '素材使用',
            path: '/operation/branch/material/use-log',
          },
          {
            name: '分享历史',
            path: '/operation/branch/material/share-log',
          }, {
            name: '水稻学院',
            path: '/operation/branch/branch-course',
          },
        ],
      }, {
        key: 'product_daotianToC',
        icon: CarOutlined,
        name: '水稻汽车',
        subMenu: [
          {
            name: '媒体中心',
            path: '/operation/client/news',
          }, {
            name: '广告管理',
            path: '/operation/client/advert',
          }, {
            name: '活动管理',
            path: '/operation/client/activity',
          }, {
            name: '评价管理',
            path: '/operation/client/comment',
          },
        ],
      }, {
        key: 'product_daotianToA',
        icon: UserOutlined,
        name: '水稻技师版',
        subMenu: [
          {
            name: '问答管理',
            path: '/operation/question',
          }, {
            name: '技师管理',
            path: '/operation/artificer',
          },
        ],
      }, {
        key: 'product_daotianToD',
        icon: CarOutlined,
        name: '水稻优车',
        subMenu: [
          {
            name: '广告位管理',
            path: '/operation/youche/advert',
          }, {
            name: '知识库管理',
            path: '/operation/youche/qa',
          },
        ],
      },
    ],
  }, {
    key: 'admin_data_manage',
    name: '数据管理',
    menus: [
      {
        key: 'data_manage_vehicle',
        icon: CarOutlined,
        name: '车辆',
        subMenu: [
          {
            name: '车辆管理',
            path: '/data/vehicle',
          },
        ],
      }, {
        key: 'data_manage_driver',
        icon: UserOutlined,
        name: '车主',
        subMenu: [
          {
            name: '车主管理',
            path: '/data/vehicle-owner/list',
          },
        ],
      }, {
        key: 'business_manage_part_goods',
        icon: AppstoreOutlined,
        name: '品牌及配件',
        subMenu: [
          {
            name: '品牌管理',
            path: '/business/brand',
          }, {
            name: '配件管理',
            path: '/business/part',
          },
        ],
      }, {
        key: 'data_manage_remind',
        icon: NotificationOutlined,
        name: '提醒',
        subMenu: [
          {
            name: '保养提醒',
            path: '/data/remind/maintain',
          }, {
            name: '续保提醒',
            path: '/data/remind/insurance',
          }, {
            name: '年检提醒',
            path: '/data/remind/yearly-inspection',
          },
        ],
      }, {
        key: 'data_manage_sms',
        icon: MessageOutlined,
        name: '短信',
        subMenu: [
          {
            name: '历史明细',
            path: '/data/sms/history',
          }, {
            name: '语音短信明细',
            path: '/data/sms/history-voice',
          }, {
            name: '充值记录',
            path: '/data/sms/charge',
          }, {
            name: '模板管理',
            path: '/data/sms/template',
          },
        ],
      }, {
        key: 'data_config',
        icon: DatabaseOutlined,
        name: '数据配置',
        subMenu: [
          {
            name: '项目管理',
            path: '/data/data-manage/maintain-item',
          },
        ],
      },
    ],
  }, {
    key: 'admin_system_setting',
    name: '系统设置',
    menus: [
      {
        key: 'system_setting_permission',
        icon: LockOutlined,
        name: '稻田管理',
        subMenu: [
          {
            name: '功能设置',
            path: '/settings/system',
          }, {
            name: '版本权限',
            path: '/settings/permission/edition',
          }, {
            name: '角色权限',
            path: '/settings/permission/role',
          },
        ],
      }, {
        key: 'system_setting_dealer',
        icon: ContactsOutlined,
        name: '汽配管理',
        subMenu: [
          {
            name: '功能设置',
            path: '/settings/dealer/system',
          }, {
            name: '角色权限',
            path: '/settings/dealer/role',
          },
        ],
      }, {
        key: 'system_setting_branch',
        icon: ContactsOutlined,
        name: '城市团队',
        subMenu: [
          {
            name: '功能设置',
            path: '/settings/branch/system',
          }, {
            name: '角色权限',
            path: '/settings/branch/role',
          },
        ],
      }, {
        key: 'system_setting_account',
        icon: ContactsOutlined,
        name: '账号',
        subMenu: [
          {
            name: '账号管理',
            path: '/settings/account',
          },
        ],
      }, {
        key: 'system_setting_auto_models',
        icon: CarOutlined,
        name: '车型维护',
        subMenu: [
          {
            name: '车型维护',
            path: '/settings/auto-models',
          },
        ],
      },
    ],
  },
];

export default menuAdmin;
