import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import React from 'react';
import WebSocket from 'react-websocket';
import apiAdmin from '../../../../configs/api-admin';

import { getNoticeList } from '../../../../reducers/notice/noticeActions';
import AuthUtil from '../../../../utils/AuthUtil';

const _ = require('lodash');

class NewNotice extends React.Component {
  constructor(props) {
    super(props);
    this.time = null;
    this.state = {
      noticeShow: false,
    };
    this.handleGetMessage = this.handleGetMessage.bind(this);
  }

  componentDidMount() {
    this.props.actions.getNoticeList();
  }

  handleGetMessage(value) {
    // todo WebSocket收到信号后发送请求获取数据， 数据存储到redux中；
    const constArray = _.split(value, '|');

    if (Notification.permission === 'granted') {
      this.popNotice(constArray);
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then(() => {
        this.popNotice(constArray);
      });
    }
  }

  popNotice(value) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(value[0], { body: value[1] });

      setTimeout(() => {
        notification.close();
      }, 5000);

      this.props.actions.getNoticeList();

      notification.onclick = function() {
        location.href = `/youche/order/detail/${value[2]}`;
        notification.close();
      };
    }
  }

  render() {
    return (
      <WebSocket
        url={apiAdmin.webSocket(AuthUtil.getLoginUserInfo().uid)}
        onMessage={this.handleGetMessage}
      />
    );
  }
}

function mapStateToProps(state) {
  const { isFetching, page, total } = state.notice;
  return { isFetching, page, total };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      getNoticeList,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewNotice);
