import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';

import apiAdmin from '../../configs/api-admin';

import DownloadGoogle from '../../components/DownloadGoogle';

import server from '../../utils/server';
import validator from '../../utils/validator';
import AuthUtil from '../../utils/AuthUtil';

require('../../styles/login.less');

const logo = require('../../images/login/daotian_logo.png');
const topLogo = require('../../images/login/top_logo.png');

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code_id: '',
      btn_value: '获取验证码',
      isDisabled: false,
      opacity: 1,
      isLoginBtn: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getVerifyCode = this.getVerifyCode.bind(this);
  }

  componentDidMount() {
    if (AuthUtil.isLogin()) {
      location.href = '/';
    }
  }

  handleSubmit() {
    const phone = this.formRef.getFieldValue('phone');
    const code = this.formRef.getFieldValue('code');

    if (!phone || !validator.phone(phone)) {
      message.error('请输入正确的电话号码');
      return false;
    }
    if (!code) {
      message.error('请输入验证码');
      return false;
    }

    this.setState({ isLoginBtn: true });
    const loginData = this.formRef.getFieldsValue();
    loginData.code_id = this.state.code_id;
    loginData.permission = 'no-login';
    server.post(apiAdmin.system.login(), loginData, (loginRes) => {
      window.accessToken = `Bearer ${loginRes.res.access_token}`;
      localStorage.setItem('refressToken', `${loginRes.res.refress_token}`);
      server.get(apiAdmin.system.userInfo(), (data) => {
        const { user } = data.res;
        if (user && Object.keys(user).length > 0) {
          user.uid = user._id;
          localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
          const date = new Date();
          const hour = date.getHours();
          const minute = date.getMinutes();
          localStorage.setItem('last_update', ((hour * 60) + minute).toString());
          localStorage.setItem('last_update_day', new Date().getDate().toString());
          message.success('登录成功');

          setTimeout(() => {
            location.href = '/';
          }, 500);
        } else {
          message.error('获取用户信息失败, 请重新登录');
          this.setState({ isLoginBtn: false });
        }
      });
    });
  }

  getVerifyCode() {
    const phone = this.formRef.getFieldValue('phone');
    if (!phone) {
      message.error('请输入电话号码');
      return false;
    }

    let num = 10;
    this.setState({ isDisabled: true, opacity: 0.5 });

    let btn_value = `${num}s`;
    this.setState({ btn_value });

    let time = setInterval(() => {
      num--;
      btn_value = `${num}s`;
      this.setState({ btn_value });

      if (num === 0) {
        this.setState({
          isDisabled: false,
          opacity: 1,
          btn_value: '获取验证码',
        });
        clearInterval(time);
        time = undefined;
      }
    }, 1000);

    server.post(apiAdmin.system.getVerifyCode(),
      { phone, permission: 'no-login' }, (data) => {
        message.info('验证码已发送', 3);
        const { sms } = data.res;
        if (sms && Object.keys(sms).length > 0) {
          this.setState({ code_id: sms._id });
        } else {
          message.error('验证码获取失败');
        }
      });
  }

  render() {
    const {
      btn_value,
      isDisabled,
      isLoginBtn,
    } = this.state;

    return (
      <div className="login-page">
        <div className="content">
          <header>
            <div className="logo" style={{ marginTop: 25 }}>
              <img src={logo} alt="" />
            </div>
            <div className="phone">
              <span>寻求帮助: </span>
              <span>400-900-9556</span>
            </div>
            <div className="top-logo">
              <span>系统支持: </span>
              <img src={topLogo} alt="" />
            </div>
          </header>

          <section style={{ height: document.body.clientHeight - 165, minHeight: '550px' }}>
            <div className="section-content">
              <div className="word" style={{ marginTop: 250 }}>
                <div className="name">稻田智能门店管家</div>
                <div className="line" />
                <div className="slogan-one">一站式解决门店营销获客、运营管理、资金管理三大烦恼</div>
              </div>

              <div className="sign-in" style={{ marginTop: 160, marginLeft: 93 }}>
                <div className="accountLogin">
                  <p>账号登录</p>
                </div>
                <Form ref={ref => this.formRef = ref} onFinish={this.handleSubmit}>
                  {this.state.code_id && (
                    <Form.Item
                      name="code_id"
                      style={{ height: 0, margin: 0 }}
                      initialValue={this.state.code_id}
                    >
                      <Input type="hidden" />
                    </Form.Item>
                  )}

                  <Row style={{ height: 90 }}>
                    <Col span={15}>
                      <Form.Item name="phone">
                        <Input className="input-phone input" size="large" placeholder="请输入手机号" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Button
                        disabled={isDisabled}
                        className="code-button"
                        onClick={this.getVerifyCode.bind(this)}
                      >
                        {btn_value}
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ height: 65, marginTop: 0 }}>
                    <Col span={24}>
                      <Form.Item name="code">
                        <Input
                          className="input-password input"
                          onPressEnter={this.handleSubmit}
                          placeholder="请输入验证码"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button
                        className="submit-button"
                        type="primary"
                        htmlType="submit"
                        disabled={isLoginBtn}
                      >
                        登录
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </section>

          <footer>
            <p>
              您正在使用稻田智能门店管家 <a href="https://www.shuidao.com/desktop/">关于水稻</a>
              | <a href="https://www.shuidao.com/desktop/about.html">联系我们</a>
              | Copyright © 2012-{new Date().getFullYear()} 北京稻成科技有限公司. All Rights Reserved
              <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
                京ICP备15040810号
              </a>
            </p>
          </footer>
        </div>

        <DownloadGoogle />
      </div>
    );
  }
}
