import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import React from 'react';
import { Link, Switch } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { PayCircleOutlined, AppstoreOutlined, UserOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/zh-cn';

import routes from './routes';
import PrivateRoute from '../PrivateRoute';

import apiAdmin from '../../configs/api-admin';
import menuAdmin from '../../configs/menu-admin';
import menuAdminRegion from '../../configs/menu-admin-region';
import menuAdminChengshi from '../../configs/menu-admin-chengshi';
import menuAdminNewenergy from '../../configs/menu-admin-newenergy';
import DownloadGoogle from '../../components/DownloadGoogle';

import AuthUtil from '../../utils/AuthUtil';

import NewNoticeCount from './youche/order/NewNoticeCount';
import NewNotice from './youche/order/NewNotice';

import { getUserPermissions, setUserPermissions } from '../../reducers/auth/authActions';

moment.locale('zh-cn');

require('babel-polyfill');

require('../../styles/common.less');
require('../../styles/app.less');
require('../../styles/layout-admin.css');

require('../../styles/reset.css');

const logo = require('../../images/logo/logo_shuidao@2x.png');

const { Header, Footer, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
const MenuItemGroup = Menu.ItemGroup;

const packageFile = require('../../../package.json');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavbar: sessionStorage.getItem('activeNavbar') || 'dashboard',
      activeMenu: 'dashboard_summary',
      collapsed: localStorage.getItem('collapsed') === 'true' || false,
      openKeys: ['/dashboard/summary/today'],

      permissionMap: new Map(),
      adminSubMenu: [],
    };

    [
      'handleLogout',
      'handleToggle',
      'handleMenuClick',
      'handleNavbarSelect',
    ].forEach(method => this[method] = this[method].bind(this));
  }

  componentDidMount() {
    const that = this;
    // 区域管理员，查看城市团队 isRegionAdministrator; 新能源账号指查看新能源模块 isNewEnergy
    if (AuthUtil.isRegionAdministrator() || AuthUtil.isNewEnergy()) {
      const path = location.pathname;
      let menuList = menuAdminChengshi;
      let activeNavbar = 'business_manage';

      if (AuthUtil.isNewEnergy()) {
        menuList = menuAdminNewenergy;
        activeNavbar = 'admin_new_energy';
      }

      if (!this.isInPathMenu(path, menuList[0].menus)) {
        location.pathname = AuthUtil.isNewEnergy() ? 'new-energy/diagnose' : '/business/order';
      }

      const defaultOpenKeys = [];
      menuList.forEach((navbar) => {
        if (navbar.key === activeNavbar) {
          const currentMenus = navbar.menus;
          currentMenus.forEach((menu) => {
            if (menu.subMenu && menu.subMenu.length > 0) {
              menu.subMenu.forEach((subMenu) => {
                if (subMenu.path === path) {
                  defaultOpenKeys.push(menu.key);
                }
              });
            }
          });
        }
      });
      that.setState({
        activeMenu: path,
        openKeys: defaultOpenKeys,
        activeNavbar,
        adminSubMenu: menuList[0].menus,
      });
    } else {
      this.initPage();
    }
    AuthUtil.checkLoginUserInfo(apiAdmin.system.userInfo());
  }

  handleLogout() {
    sessionStorage.clear();
    localStorage.clear();
    location.href = '/login';
  }

  handleToggle() {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.state.collapsed) {
      localStorage.setItem('collapsed', false);
    } else {
      localStorage.setItem('collapsed', true);
    }
  }

  handleMenuClick(e) {
    const event = e;
    this.setState({ activeMenu: event.key });
    sessionStorage.setItem('menu', event.key);
  }

  handleOpenChange = (keys) => {
    const { openKeys } = this.state;
    const datas = [];
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      // 单独取出三级导航的key
      if (latestOpenKey === 'set-meal' || latestOpenKey === 'member') {
        datas.unshift('activity_manage');
      }
      datas.push(latestOpenKey);
    }
    this.setState({ openKeys: datas });
  };

  handleNavbarSelect(params) {
    let { activeMenu } = this.state;

    this.setState({
      activeNavbar: params.key,
    });
    menuAdmin.forEach((navbar) => {
      if (navbar.key === params.key) {
        const defaultOpenKeys = [];

        const firstSubMenu = navbar.menus[0].subMenu[0];
        if (firstSubMenu) {
          activeMenu = firstSubMenu.path;

          setTimeout(() => {
            const firstMenuElement = document.getElementById(firstSubMenu.path);
            if (firstMenuElement) {
              firstMenuElement.click();
            }
          }, 300);
        }

        sessionStorage.setItem('activeNavbar', navbar.key);

        if (params.key === 'daotian') {
          this.setState({
            activeMenu,
            openKeys: [navbar.menus[0].key],
            adminSubMenu: navbar.menus,
          });
        } else {
          navbar.menus.forEach((menu) => {
            defaultOpenKeys.push(menu.key);

            this.setState({
              activeMenu,
              openKeys: [navbar.menus[0].key],
              adminSubMenu: navbar.menus,
            });

            return false;
          });
        }
      }
    });
  }

  initPage() {
    const path = String(location.pathname).replace(/\/\d+/g, '');

    let adminSubMenu = [];
    const defaultOpenKeys = [];
    let activeNavbar = this.getMenuItemActiveNavbar(path);
    if (!activeNavbar) {
      activeNavbar = this.state.activeNavbar;
    }
    menuAdmin.forEach((navbar) => {
      if (navbar.key === activeNavbar) {
        const currentMenus = navbar.menus;
        adminSubMenu = currentMenus;
        currentMenus.forEach((menu) => {
          if (menu.subMenu && menu.subMenu.length > 0) {
            menu.subMenu.forEach((subMenu) => {
              if (subMenu.path === path) {
                defaultOpenKeys.push(menu.key);
              }
              if (subMenu.children && subMenu.children.length > 0) {
                subMenu.children.forEach((val) => {
                  if (val.path === path) {
                    defaultOpenKeys.push(menu.key);
                    defaultOpenKeys.push(subMenu.key);
                  }
                });
              }
            });
          }
        });
      }
    });

    this.setState({
      activeNavbar,
      activeMenu: path,
      adminSubMenu,
      openKeys: defaultOpenKeys,
    });
    sessionStorage.setItem('menu', path);
  }

  getMenuItemActiveNavbar(currentPath) {
    const menus = require('../../configs/menu-admin').default;
    let activeNavbar = '';

    if (menus && menus.length > 0) {
      try {
        menus.forEach((menuItem) => {
          if (menuItem.menus && menuItem.menus.length > 0) {
            menuItem.menus.forEach((subMenuItem) => {
              if (subMenuItem.subMenu && subMenuItem.subMenu.length > 0) {
                subMenuItem.subMenu.forEach((subMenu) => {
                  if (subMenu.path === currentPath) {
                    activeNavbar = menuItem.key;
                    throw new Error('找到menuItem的Key啦');
                  }
                  if (subMenu.children && subMenu.children.length > 0) {
                    subMenu.children.forEach((val)=> {
                      if (val.path === currentPath) {
                        activeNavbar = menuItem.key;
                        throw new Error('找到menuItem的Key啦');
                      }
                    });
                  }
                });
              }
            });
          }
        });
      } catch (e) {
      }
    }
    return activeNavbar;
  }

  isInRegionMenu(path) {
    for (const menu of menuAdminRegion) {
      for (const subMenu of menu.subMenu) {
        if (path === subMenu.path) {
          return true;
        } else if (path.includes('/youche/product') || path.includes('/youche/plan') ||
          path.includes('/youche/order/detail')) {
          return true;
        }
      }
    }
    return false;
  }

  isInPathMenu(path, menus) {
    for (const menu of menus) {
      for (const subMenu of menu.subMenu) {
        if (path === subMenu.path) {
          return true;
        } else if (path.includes('/branch/team/detail') || path.includes('/branch/user-track') ||
          path.includes('/branch/user-yingyan-track') || path.includes('/daotian/repair-shop/detail')) {
          return true;
        }
      }
    }
    return false;
  }

  renderSubMenuIcon(collapsed, sub) {
    const MenuIcon = sub.icon;
    return (
      <span>
        {!collapsed && <MenuIcon />}
        <span>{sub.name}</span>
      </span>
    );
  }

  renderNavigation() {
    // 区域管理员只能查看城市团队， 新能源部门只查看 新能源模块
    if (AuthUtil.isRegionAdministrator() || AuthUtil.isNewEnergy()) {
      let menus = menuAdminChengshi;
      if (AuthUtil.isNewEnergyAdmin()) {
        menus = menuAdminNewenergy;
      } else if (AuthUtil.isNewEnergy()) {
        const newMenus = [];
        menuAdminNewenergy[0].menus.forEach((item) => {
          if (item.key !== 'new_energy__store') {
            newMenus.push(item);
          }
        });
        menuAdminNewenergy[0].menus = newMenus;
        menus = menuAdminNewenergy;
      }
      return (
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[this.state.activeNavbar]}
          style={{ lineHeight: '50px', paddingLeft: '155px' }}
          onSelect={this.handleNavbarSelect}
        >
          {menus.map(menu => <MenuItem key={menu.key}>{menu.name}</MenuItem>)}
        </Menu>
      );
    }
    if (!AuthUtil.isHeadquarterRisk()) {
      return (
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[this.state.activeNavbar]}
          style={{ lineHeight: '50px', paddingLeft: screen.width <= 1024 ? '10px' : '155px' }}
          onSelect={this.handleNavbarSelect}
        >
          {menuAdmin.map(menu => <MenuItem key={menu.key}>{menu.name}</MenuItem>)}
        </Menu>
      );
    }
    // 风控只能看到水稻优车
    return (
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[this.state.activeNavbar]}
        style={{ lineHeight: '50px', paddingLeft: '155px' }}
        onSelect={this.handleNavbarSelect}
      >
        <MenuItem key="daotianToD">水稻优车</MenuItem>
      </Menu>
    );
  }

  renderSider() {
    const { activeMenu, openKeys, adminSubMenu, collapsed } = this.state;
    const menuItemStyle = { height: '28px', lineHeight: '28px' };

    if (!AuthUtil.isHeadquarterRisk()) {
      return (
        <Menu
          mode="inline"
          selectedKeys={[activeMenu]}
          openKeys={openKeys}
          onOpenChange={this.handleOpenChange}
          onClick={this.handleMenuClick}
        >
          {adminSubMenu.length > 0 && (
            adminSubMenu.map(sub => (
              <SubMenu
                key={sub.key}
                title={this.renderSubMenuIcon(collapsed, sub)}
              >
                {sub.subMenu.map((menuList) => {
                  if (menuList.path === 'group') {
                    return (
                      <MenuItemGroup key={menuList.name} title={menuList.name}>
                        {menuList.items.map(groupItem => (
                          <Menu.Item key={groupItem.path} style={menuItemStyle}>
                            <Link
                              id={groupItem.path}
                              to={{ pathname: groupItem.path }}
                              target={groupItem.target || ''}
                            >
                              <span>{groupItem.name}</span>
                            </Link>
                          </Menu.Item>
                        ))}
                      </MenuItemGroup>
                    );
                  }

                  if (menuList.type === 'subMenu') {
                    return (
                      <SubMenu key={menuList.key} title={menuList.name}>
                        {menuList.children.map(item => (
                          <MenuItem key={item.path} style={menuItemStyle}>
                            <Link
                              id={item.path}
                              to={{ pathname: item.path }}
                              target={item.target || ''}
                            >
                              <span>{item.name}</span>
                            </Link>
                          </MenuItem>
                        ))}
                      </SubMenu>
                    );
                  }

                  return (
                    <MenuItem key={menuList.path} style={menuItemStyle}>
                      <Link
                        id={menuList.path}
                        to={{ pathname: menuList.path }}
                        target={menuList.target || ''}
                      >
                        <span>{menuList.name}</span>
                      </Link>
                    </MenuItem>
                  );
                })}
              </SubMenu>
            ))
          )}
        </Menu>
      );
    }
    // 风控
    return (
      <Menu
        mode="inline"
        onClick={this.handleMenuClick}
        selectedKeys={[activeMenu]}
        onOpenChange={this.handleOpenChange}
        openKeys={openKeys}
      >
        <MenuItem key="/new-car/workbench" style={menuItemStyle}>
          <Link id="/new-car/workbench" to={{ pathname: '/youche/workbench' }}>
            <span><AppstoreOutlined />工作台</span>
          </Link>
        </MenuItem>

        <SubMenu
          key="daotianToD_order"
          title={(
            <span>
              {!collapsed && <PayCircleOutlined />}
              <span>订单</span>
            </span>
          )}
        >
          <MenuItem key="/new-car/order" style={menuItemStyle}>
            <Link id="/new-car/order" to={{ pathname: '/youche/order' }}>
              <span>订单管理</span>
            </Link>
          </MenuItem>
        </SubMenu>
      </Menu>
    );
  }

  render() {
    const { uid, name, department } = AuthUtil.getLoginUserInfo();

    const showMenu = classNames({
      'layout-menu': true,
      hide: !uid,
    });

    const showSetting = classNames({
      'layout-setting': !!uid,
      'bg-white': AuthUtil.isRegionAdministrator(),
      hide: !uid,
    });

    const settingContainer = classNames({
      'setting-container': true,
      'no-company': department >= 0,
    });

    return (
      <Layout>
        <Header>
          <div className="logo">
            <img src={logo} style={{ width: 144, height: 25.6 }} alt="水稻汽车" />
          </div>

          {this.renderNavigation()}

          <div className={showSetting}>
            <div className={settingContainer}>
              <div className="user-setting">
                {AuthUtil.isHeadquarterRisk() && <NewNoticeCount />}
                <p
                  className="ant-dropdown-link inline-block ml20"
                  style={{ cursor: 'pointer', color: '#fff' }}
                >
                  <UserOutlined style={{ marginRight: 5 }} />
                  {name}
                  <a className="btn-logout" onClick={this.handleLogout}>退出</a>
                </p>
              </div>
            </div>
          </div>
        </Header>

        <Content
          style={{
            marginBottom: '10px',
            background: '#fff',
            border: '1px solid #e1e1e1',
            minHeight: document.body.clientHeight - 96,
          }}
        >
          <Layout>
            <Sider width={160} style={{ minHeight: document.body.clientHeight - 135 }}>
              <div className={showMenu}>
                {this.renderSider()}
              </div>
            </Sider>

            <Content
              style={{
                background: '#fff',
                padding: '20px',
                minHeight: document.body.clientHeight - 135,
              }}
            >
              <Switch>
                {routes.map((route, index) => (
                  <PrivateRoute
                    key={index.toString()}
                    path={route.path}
                    exact={route.exact}
                    component={route.render}
                  />
                ))}
              </Switch>
            </Content>
          </Layout>
        </Content>

        {AuthUtil.isHeadquarterRisk() && <NewNotice />}
        <Footer>
          水稻汽车 v{packageFile.versionAdmin} 版权所有 ©{new Date().getFullYear()}
          <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
            京ICP备15040810号
          </a>
        </Footer>

        <DownloadGoogle />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { userPermissions } = state.auth;
  return { userPermissions };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getUserPermissions, setUserPermissions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
