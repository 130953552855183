import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import React from 'react';
import { Badge, Popover } from 'antd';
import { BellOutlined } from '@ant-design/icons';

import { accept, acceptAll, getNoticeList } from '../../../../reducers/notice/noticeActions';

class NewNoticeCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: '',
    };

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleAcceptAll = this.handleAcceptAll.bind(this);
  }

  handleAccept(e, id) {
    e.stopPropagation();
    this.props.actions.accept(id);
  }

  handleAcceptAll(e) {
    e.stopPropagation();
    this.props.actions.acceptAll();
  }

  handleMouseEnter(id) {
    this.setState({ itemId: id });
  }

  handleMouseLeave() {
    this.setState({ itemId: '' });
  }

  hidePopover(orderId, pushId) {
    this.props.actions.accept(pushId).then(() => {
      this.props.actions.getNoticeList();
      setTimeout(() => {
        location.href = `/youche/order/detail/${orderId}`;
      }, 300);
    });
  }

  linkToNotices() {
    location.href = '/chengshi/notices';
  }

  render() {
    const { itemId } = this.state;
    const { list = [] } = this.props;

    return (
      <Popover
        onClick={this.handleClick}
        title={
          <span>
            通知
            <span
              className="pull-right font-size-12"
              style={{ cursor: 'pointer' }}
              onClick={this.handleAcceptAll}
            >
              全部标为已读
            </span>
          </span>
        }
        trigger="click"
        placement="bottomRight"
        content={
          <div className="font-size-12">
            <div style={{ maxHeight: '280px', overflowY: 'auto' }}>
              {list.length > 0
                ? list.map(item => {
                  let msg = {};
                  try {
                    msg = JSON.parse(item.msg);
                  } catch (e) {
                  }

                  return (
                    <div
                      key={item._id}
                      className="order-notices"
                      style={{ padding: 0 }}
                    >
                      <div
                        className="notice-new"
                        style={{ width: '380px' }}
                        onClick={() => this.hidePopover(msg.order_id, item._id)}
                        onMouseEnter={() => this.handleMouseEnter(item._id)}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <p className="mt3 grey-9c9c9c">{item.ctime}</p>
                        <div>
                          <span style={{ fontWeight: 'bolder' }}>{msg.from_name}</span>&nbsp;&nbsp;
                          <span>提交订单至风控</span>
                        </div>

                        <div>
                          <p>订单详情：{(msg.customer_name || '--') + '-' +
                          (msg.product_name || '--')}</p>
                          <p>订单状态：{msg.order_status}</p>
                        </div>

                        <div
                          className={Number(item._id) === Number(itemId)
                            ? 'button button-count'
                            : 'hide'}
                          onClick={e => this.handleAccept(e, item._id)}
                        >
                          标为已读
                        </div>
                      </div>
                    </div>
                  );
                })
                : (
                  <div className="mt20 font-size-16" style={{ textAlign: 'center' }}>-没有新通知-</div>
                )}
            </div>
            <span
              className="inline-block"
              style={{
                color: '#6c6c6c',
                cursor: 'pointer',
                marginLeft: '304px',
                paddingTop: '12px',
              }}
              onClick={this.linkToNotices}
            >
              查看全部通知
            </span>
          </div>
        }
      >
        <Badge dot={list.length > 0}>
          <BellOutlined style={{ color: 'white', cursor: 'pointer' }} />
        </Badge>
      </Popover>
    );
  }
}

function mapStateToProps(state) {
  const { list } = state.notice;
  return { list };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      getNoticeList,
      acceptAll,
      accept,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewNoticeCount);
