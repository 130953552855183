import {
  CalendarOutlined,
  CarOutlined,
  ShopOutlined,
  ControlOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';

const menuAdminNewEnergy = [
  {
    key: 'admin_new_energy',
    name: '新能源',
    menus: [
      {
        key: 'new_energy_diagnose',
        icon: CarOutlined,
        name: '车检设备',
        subMenu: [
          {
            name: '项目管理',
            path: '/new-energy/diagnose',
          },
          {
            name: '检测枪',
            path: '/new-energy/detection-gun-management/index',
          }, {
            name: '检测结果',
            path: '/new-energy/detection-gun-management/check-result/index',
          },
        ],
      },
      {
        key: 'new_energy_course',
        icon: CalendarOutlined,
        name: '课程管理',
        subMenu: [
          {
            name: '商品',
            path: '/new-energy/course-goods',
          },
          {
            name: '优惠券',
            path: '/new-energy/course-coupon',
          },
        ],
      },
      {
        key: 'electric_course',
        icon: CalendarOutlined,
        name: '新能源课程',
        subMenu: [
          {
            name: '线下课程',
            path: '/new-energy/electric-course/offline',
          },
          {
            name: '线上课程',
            path: '/new-energy/electric-course/online',
          },
        ],
      },
      {
        key: 'new_energy_store_clue',
        icon: ShopOutlined,
        name: '门店线索',
        subMenu: [
          {
            name: '线索管理',
            path: '/new-energy/clue',
          },
          {
            name: '线索跟进',
            path: '/new-energy/clue-follow-up',
          },
        ],
      },
      {
        key: 'new_energy_charge-station',
        icon: ControlOutlined,
        name: '电桩管理',
        subMenu: [
          {
            name: '充电站',
            path: '/new-energy/charge/station',
          },
          {
            name: '订单管理',
            path: '/new-energy/charge/order',
          },
        ],
      }, {
        key: 'new_energy_maintain-material',
        icon: SnippetsOutlined,
        name: '技术支持',
        subMenu: [
          {
            name: '维修资料',
            path: '/new-energy/maintain-material',
          },
          {
            name: '水稻商城',
            path: '/new-energy/shuidao-goods',
          },
          {
            name: '维修案例',
            path: '/new-energy/maintain-case',
          },
          {
            name: '资料A整理',
            path: '/new-energy/zjxc-material',
          },
          {
            name: '智慧修车',
            path: '/new-energy/maintain-qas',
          },
        ],
      }, {
        key: 'new_energy__store',
        icon: ShopOutlined,
        name: '门店管理',
        subMenu: [
          {
            name: '入住门店',
            path: '/new-energy/company',
          },
        ],
      },
    ],
  },
];

export default menuAdminNewEnergy;
