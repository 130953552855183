import {
  DesktopOutlined, InboxOutlined,
  MedicineBoxOutlined,
  MoneyCollectOutlined,
  NotificationOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from '@ant-design/icons';

const menuAdminChengshi = [
  {
    key: 'business_manage',
    name: '城市团队',
    menus: [
      {
        key: 'business_manage_order',
        icon: ShoppingCartOutlined,
        name: '商品订单',
        subMenu: [
          {
            name: '订单管理',
            path: '/business/order',
          }, {
            name: '订单削减',
            path: '/business/order-cut',
          }, {
            name: '退货/拒收',
            path: '/business/order-return',
          }, {
            name: '商品管理',
            path: '/business/goods-item',
          }, {
            name: '商品类别',
            path: '/business/goods',
          }, {
            name: '品牌意向',
            path: '/business/brand-intention',
          }, {
            name: '市场洞察',
            path: '/business/market-insight',
          },
        ],
      }, {
        key: 'business_manage_use_log',
        icon: DesktopOutlined,
        name: '拜访管理',
        subMenu: [
          {
            name: '拜访记录',
            path: '/business/visit',
          }, {
            name: '数据收集',
            path: '/business/journal',
          }, {
            name: '拜访计划',
            path: '/business/visit-plan',
          }, {
            name: '使用记录',
            path: '/business/use-log',
          }, {
            name: '直播记录',
            path: '/business/wxwork-living-log',
          },
        ],
      }, {
        key: 'warehouse_manage',
        icon: InboxOutlined,
        name: '仓库管理',
        subMenu: [
          {
            name: '仓库库存',
            path: '/warehouse/index',
          }, {
            name: '仓库盘点',
            path: '/warehouse/stocktaking',
          }, {
            name: '损益管理',
            path: '/warehouse/income-statement',
          }, {
            name: '出库管理',
            path: '/warehouse/output',
          },
        ],
      }, {
        key: 'business_manage_maintain',
        icon: MedicineBoxOutlined,
        name: '同城活动',
        subMenu: [
          {
            name: '套餐卡管理',
            path: '/business/maintain/coupon-card',
          }, {
            name: '顾客线索',
            path: '/business/maintain/customer-clue',
          }, {
            name: '购买记录',
            path: '/business/maintain/buy-logs-log',
          }, {
            name: '核销记录',
            path: '/business/maintain/consume',
          },
        ],
      }, {
        key: 'business_manage_activity',
        icon: NotificationOutlined,
        name: '门店活动',
        subMenu: [
          {
            name: '优惠活动',
            path: '/business/activity',
          }, {
            name: '品牌活动',
            path: '/business/activity-brand',
          }, {
            name: '报名活动',
            path: '/business/activity-event',
          }, {
            name: '推送记录',
            path: '/business/news-push-log',
          },
          // 暂时隐藏
          // {
          //   name: '参与记录',
          //   path: '/business/activity-logs',
          // },
        ],
      }, {
        key: 'system_setting_team',
        icon: TeamOutlined,
        name: '团队管理',
        subMenu: [
          {
            name: '团队管理',
            path: '/branch/team',
          }, {
            name: '员工管理',
            path: '/branch/user',
          },
        ],
      }, {
        key: 'business_manage_money',
        icon: MoneyCollectOutlined,
        name: '资金管理',
        subMenu: [
          {
            name: '应收账款',
            path: '/business/finance/on-account',
          }, {
            name: '支付汇总',
            path: '/business/finance/transfer',
          }, {
            name: '线上收款',
            path: '/business/finance/payments',
          }, {
            name: '滞纳金管理',
            path: '/business/finance/overdue-fine',
          }, {
            name: '市场费用',
            path: '/business/finance/market-wallet',
          }, /* , {
            name: '转账记录',
            path: '/business/finance/transfer-log',
          } */
        ],
      },
    ],
  },
];

export default menuAdminChengshi;
