const menuAdminRegion = [
  {
    key: 'daotianToD_product',
    icon: 'appstore-o',
    name: '产品',
    subMenu: [
      {
        name: '车型产品',
        path: '/youche/product/vehicle',
      }, {
        name: '金融产品',
        path: '/youche/product/finance',
      },
    ],
  }, {
    key: 'daotianToD_plan',
    icon: 'file-text',
    name: '方案',
    subMenu: [
      {
        name: '车型方案',
        path: '/youche/plan/vehicle',
      }, {
        name: '金融方案',
        path: '/youche/plan/finance',
      },
    ],
  }, {
    key: 'daotianToD_order',
    icon: 'car',
    name: '订单管理',
    subMenu: [
      {
        name: '意向订单',
        path: '/youche/order/intention',
      }, {
        name: '订单管理',
        path: '/youche/order',
      }, {
        name: '订单收益',
        path: '/youche/order/profit',
      }, {
        name: '订单统计',
        path: '/youche/order/statistics',
      },
    ],
  },
];

export default menuAdminRegion;
