import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN'; // antd 默认为英文， 这里改为中文
import 'nprogress/nprogress.css';
import '@ant-design/compatible/assets/index.css';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import configureStore from '../../store/configureStore';
import AUthUtil from '../../utils/AuthUtil';
import server from '../../utils/server';
import Root from './Root';
/**
 * ## States
 * defines initial state
 *
 */
import AuthInitialState from '../../reducers/auth/authInitialState';
import ActivityInitialState from '../../reducers/activity/activityInitialState';
// 新车
import IntentionInitialState from '../../reducers/youche/order/intentionInitialState';
import OrderInitialState from '../../reducers/youche/order/orderInitialState';
import OrderDetailInitailState from '../../reducers/youche/order/detailInitialState';
import OrderNewInitialState from '../../reducers/youche/order/newInitialState';

import QaInitailState from '../../reducers/youche/qa/qaInitialState';
import NewCarBannerInitailState from '../../reducers/youche/banner/bannerInitialState';
import NewCarResourceInitailState from '../../reducers/youche/resource/resourceInitialState';
import NewCarEarningsRecordInitailState
  from '../../reducers/youche/earnings-record/earningsRecordInitialState';
import NewCarMaterialInitailState from '../../reducers/youche/material/materialInitialState';

import NewCarModelsInitailState from '../../reducers/auto-models/modelsInitialState';

import NoticeInitailState from '../../reducers/notice/noticeInitialState';

const packageFile = require('../../../package.json');

message.config({ maxCount: 1 });

/**
 *
 * ## Initial state
 * Create instances for the keys of each structure in App
 * @returns {Object} object with 4 keys
 */
function getInitialState() {
  return {
    auth: new AuthInitialState,
    activity: new ActivityInitialState,

    intention: new IntentionInitialState,
    order: new OrderInitialState,
    orderDetail: new OrderDetailInitailState,
    orderNew: new OrderNewInitialState,

    newCarQa: new QaInitailState,
    newCarBanner: new NewCarBannerInitailState,
    newCarResource: new NewCarResourceInitailState,
    newCarEarningsRecord: new NewCarEarningsRecordInitailState,
    newCarMaterial: new NewCarMaterialInitailState,

    newCarModels: new NewCarModelsInitailState,

    notice: new NoticeInitailState,
  };
}

window.env = module.hot ? 'dev' : 'dist';

const shuidaoURL = 'https://api.shuidao.com';
const yunbed = 'https://api.shuidao.yunbed.com';
const userInfo = AUthUtil.getLoginUserInfo();

server.businessModule = 'admin';
let releaseState = 'development';
if (window.env === 'dist') {
  if (window.baseURL === shuidaoURL) {
    releaseState = 'production';
  } else if (window.baseURL === yunbed) {
    releaseState = 'testflight';
  } else {
    releaseState = 'dev1';
  }
}

const BugsnagClient = bugsnag({
  apiKey: 'df5c804812af2b8a4c376c5cc5478559',
  appVersion: packageFile.versionAdmin,
  releaseStage: releaseState,
  user: {
    id: userInfo.id,
    name: userInfo.name,
    email: userInfo.phone,
  },
});
BugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = BugsnagClient.getPlugin('react');
const beforeSend = (report) => {
  report.severity = 'error';
};

const store = configureStore(getInitialState());

render(
  <ErrorBoundary beforeSend={beforeSend}>
    <ConfigProvider locale={zhCN}>
      <Root store={store} />
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById('app_admin'),
);

if (module.hot) {
  module.hot.accept();
}
